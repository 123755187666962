import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsArrowRepeat } from "react-icons/bs";
import { FaTimes, FaTrash, FaUpload } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { v4 as uuidv4 } from "uuid";
import { DropDown } from "./Buttons/Dropdown";
import { TextBox } from "./Buttons/Textbox";
import { UploadLink } from "./Editor/UploadLink";
import { Uploader } from "./MultiUpload";
import { shortVideoStore } from "./Store/shortVideoStore";
import PricingHomeOne from "./Landing/PricingHomeOne";
import axios from "./api";
import { userStore } from "./Store/userStore";
import { set } from "lodash";

export const Upload = ({ showUpload, setShowUpload, setMode }) => {
	const MySwal = withReactContent(Swal);
	const navigate = useNavigate();

	const [processingUrl, setProcessingUrl] = useState("");

	const { videoLink, setDuration, setVideoLink, videoBlobUrl, setVideoBlobUrl } = shortVideoStore(
		(state) => state
	);

	const { user, session } = userStore((state) => state);

	const [statusUpload, setStatusUpload] = useState("idle");
	const [statusSubtitle, setStatusSubtitle] = useState("idle");
	const [file, setFile] = useState(null);
	const [uploader, setUploader] = useState(undefined);
	const [isUploading, setIsUploading] = useState(false);
	const [isSubtitleGenerated, setIsSubtitleGenerated] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [fileName, setFileName] = useState("");
	const [useYoutube, setUseYoutube] = useState(false);

	const [loading, setLoading] = useState(false);
	const [videoName, setVideoName] = useState("");
	const [errorLanguage, setErrorLanguage] = useState(false);
	const [errorVideoName, setErrorVideoName] = useState(false);

	const [selectedLanguage, setSelectedLanguage] = useState(null);

	const allLanguages = [
		{ name: "English", value: "en" },
		{ name: "French", value: "fr" },
		{ name: "Spanish", value: "es" },
		{ name: "German", value: "de" },
		{ name: "Italian", value: "it" },
		{ name: "Swedish", value: "sv" },
		{ name: "Russian", value: "ru" },
		{ name: "Portuguese", value: "pt" },
		{ name: "Norwegian", value: "no" },
		{ name: "Dutch", value: "nl" },
		{ name: "Korean", value: "ko" },
		{ name: "Japanese", value: "ja" },
		{ name: "Hindi", value: "hi" },
		{ name: "Turkish", value: "tr" },
		{ name: "Indonesian", value: "id" },
	];

	const uploadBox = useRef(null);

	const handleSubtitleGeneration = async () => {
		if (!selectedLanguage) {
			setErrorLanguage(true);
			setTimeout(() => {
				setErrorLanguage(false);
			}, 500);
			return;
		}
		if (!videoName || videoName.length === 0) {
			setErrorVideoName(true);
			setTimeout(() => {
				setErrorVideoName(false);
			}, 500);
			return;
		}
		setIsSubtitleGenerated(true);
		setLoading(true);
		try {
			const params = {
				url: processingUrl,
				language: selectedLanguage,
				videoName: videoName,
			};
			const response = await axios.post("/subtitle/transcribe", params);
			if (response.data?.id) {
				navigate(`/short/${response.data.id}`, { state: { blobUrl: videoBlobUrl } });
			}
			setIsSubtitleGenerated(false);
			setLoading(false);
		} catch (error) {
			console.error("Erreur lors de la génération des sous-titres :", error);
			setIsSubtitleGenerated(false);
			setStatusSubtitle("error");
		}
	};

	useEffect(() => {
		if (file) {
			setStatusUpload("uploading");
			setUploadProgress(0);
			setIsUploading(true);
			setFileName(file.name);
			setVideoName(file.name);
			if (!videoBlobUrl) {
				setVideoBlobUrl(URL.createObjectURL(file));
			}
			let percentage = undefined;
			const keyFile = uuidv4();
			const videoUploaderOptions = {
				fileName: keyFile,
				file: file,
			};
			const ext = file.path.slice(((file.path.lastIndexOf(".") - 1) >>> 0) + 2);
			const uploader = new Uploader(videoUploaderOptions);
			setUploader(uploader);

			uploader
				.onProgress(async ({ percentage: newPercentage }) => {
					// to avoid the same percentage to be logged twice
					if (newPercentage !== percentage) {
						percentage = newPercentage;
						setUploadProgress(percentage);
					}
				})
				.onError((error) => {
					setFile(undefined);
					console.error("Erreur lors de l'upload :", error);
					setIsUploading(false);
					setStatusUpload("error");
				});

			uploader
				.start()
				.then(() => {
					const processingUrl = `https://d2yp3y5dhw00ur.cloudfront.net/${keyFile}.${ext}`;
					setProcessingUrl(processingUrl);
					setIsUploading(false);
					setStatusUpload("success");
				})
				.catch((error) => {
					setFile(undefined);
					console.error("Erreur lors de l'upload :", error);
					setIsUploading(false);

					setStatusUpload("error");
				});
		}
	}, [file]);

	const limits = {
		free: {
			size: 300,
			duration: 120,
		},
		basic: {
			size: 300,
			duration: 120,
		},
		expert: {
			size: 400,
			duration: 180,
		},
		pro: {
			size: 600,
			duration: 180,
		},
	};

	const onDrop = useCallback((acceptedFiles) => {
		if (acceptedFiles.length === 0) {
			console.error("Vous devez mettre un fichier mp4");
			return;
		}
		const file = acceptedFiles[0];
		const video = document.createElement("video");
		video.preload = "metadata";
		const videoSrc = URL.createObjectURL(file);
		video.src = videoSrc;
		setVideoBlobUrl(videoSrc);
		video.onloadedmetadata = function () {
			if (!file.type.startsWith("video")) {
				alert("You must upload a video file (.MP4, .MOV)");
				return;
			}

			const userMaxSize = limits[user?.plan]?.size || limits.free.size;
			const userMaxDuration = limits[user?.plan]?.duration || limits.free.duration;

			if (file.size / 1024 / 1024 > userMaxSize) {
				MySwal.fire({
					icon: "error",
					title: "Oops...",
					html: "File size must not exceed " + userMaxSize + "Mb. <b>Upgrade for more.</b>",
				});
				setShowUpload(false);
				return;
			}

			if (video.duration > userMaxDuration) {
				MySwal.fire({
					icon: "error",
					title: "Oops...",
					html:
						"Video duration must not exceed " +
						userMaxDuration +
						" seconds. <b>Upgrade for more.</b>",
				});
				setShowUpload(false);
				return;
			}

			if (this.videoWidth && this.videoHeight) {
				const aspectRatio = this.videoWidth / this.videoHeight;
				const targetAspectRatio = 9 / 16;

				if (Math.abs(aspectRatio - targetAspectRatio) > 0.01) {
					MySwal.fire({
						icon: "error",
						title: "Oops...",
						html: "Your video must be in vertical format (9:16).",
					});
					setShowUpload(false);
					return;
				}

				if (this.videoWidth === 2160 && this.videoHeight === 3840) {
					MySwal.fire({
						icon: "error",
						title: "Oops...",
						html: "Your video is in 4k. Please lower the dimensions of your video to 1080x1920 (Full HD).",
					});
					setShowUpload(false);
					return;
				}
			}
			setDuration(this.duration);
			setFile(file);
		};
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: "video/*",
	});

	const handleRemoveFile = () => {
		setFile(null);
		setVideoBlobUrl("");
		setStatusUpload("idle");
		setFileName("");
		setUploadProgress(0);
		setIsUploading(false);
	};

	const [showPricing, setShowPricing] = useState(false);
	const [showUploadBox, setShowUploadBox] = useState(true);
	const pricingRef = useRef(null);
	const uploadBoxRef = useRef(null);

	const handleClickOutside = (event) => {
		if (
			uploadBoxRef.current &&
			!uploadBoxRef.current.contains(event.target) &&
			!pricingRef.current?.contains(event.target)
		) {
			if (!showPricing && !loading) {
				setShowUploadBox(false);
			}
		}

		if (pricingRef.current && !pricingRef.current.contains(event.target)) {
			setShowPricing(false);
		}
	};

	useEffect(() => {
		if (!showUploadBox && !showPricing && !loading) {
			setShowUpload(false);
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showPricing, showUploadBox, loading]);

	return (
		<>
			{showPricing && (
				<div
					ref={pricingRef}
					className="max-w-screen-xl max-h-[90vh] overflow-y-scroll rounded-lg relative"
				>
					<button
						onClick={() => {
							setShowPricing(false);
						}}
						className="absolute top-2 right-2 text-lg text-white z-[9999]"
					>
						<FaTimes />
					</button>
					<PricingHomeOne showUpgrade={true} />
				</div>
			)}
			{showUploadBox && (
				<div
					ref={uploadBoxRef}
					className={`bg-gray-700 ${loading ? "loadingbox" : "notloadingbox"} ${
						showPricing ? "w-0 h-0" : "w-[500px] h-[450px]"
					} `}
				>
					<div className="z-10 w-full h-full flex items-center justify-center px-16 py-24">
						<div className="w-full flex flex-col gap-4 justify-center items-center">
							{/* {!file && (
								<div className="hidden w-full md:block">
									<UploadLink
										loading={loading}
										setLoading={setLoading}
										setUseYoutube={setUseYoutube}
										showPricing={showPricing}
										setShowPricing={setShowPricing}
										setShowUploadBox={setShowUploadBox}
										setShowUpload={setShowUpload}
										setMode={setMode}
									/>
									{!useYoutube && (
										<div className="w-full flex gap-2 items-center">
											<div className="flex-grow h-[1px] bg-gray-700"></div>
											<span className="text-[9px] text-gray-400">Or</span>
											<div className="flex-grow h-[1px] bg-gray-700"></div>
										</div>
									)}
								</div>
							)} */}
							{!useYoutube && (
								<>
									{statusUpload == "idle" ? (
										<div
											{...getRootProps()}
											className={`flex flex-col items-center justify-center p-4 w-full h-full border-[1px] border-dashed rounded-lg cursor-pointer  bg-gray-800 border-gray-600 hover:border-blue-500 transition duration-300 
										${isDragActive ? "border-blue-500 bg-blue-100" : "border-gray-300"}`}
										>
											<input id="dropzone-file" {...getInputProps()} className="hidden" />
											<svg
												className="w-10 h-10 mb-3 text-gray-400"
												stroke="currentColor"
												fill="none"
												viewBox="0 0 24 24"
												aria-hidden="true"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
												/>
											</svg>
											{isDragActive ? (
												<p className="mb-2 text-sm  text-gray-400">Drop files here...</p>
											) : (
												<>
													<p className="mb-2 hidden sm:block text-sm text-center text-gray-400">
														Click here or drag and drop to upload a video
													</p>
													<p className="mb-2 block sm:hidden text-sm text-center text-gray-400">
														Click here to upload a video
													</p>
												</>
											)}

											<span className="text-xs text-center text-gray-400">
												MP4, MOV (MAX. 300Mb & 120s)
											</span>
											<span className="text-xs text-center text-gray-400">
												Ratio: 9:16 (vertical format)
											</span>
										</div>
									) : (
										<div className="w-full flex flex-col">
											{!isSubtitleGenerated && statusSubtitle === "idle" ? (
												<>
													<TextBox
														text={videoName}
														setText={setVideoName}
														placeholder={"Short name"}
														maxLength={100}
														error={errorVideoName}
													/>
													<div className="w-full flex gap-2 mb-4 mt-3">
														<DropDown
															options={allLanguages}
															selectedValue={selectedLanguage}
															setSelectedValue={setSelectedLanguage}
															error={errorLanguage}
														/>
														<button
															onClick={() => {
																handleSubtitleGeneration();
															}}
															disabled={statusUpload !== "success"}
															className={`flex w-48 justify-center gap-1 px-2 py-1 rounded-lg text-sm items-center 
														${
															statusUpload !== "success"
																? "bg-gray-700 cursor-not-allowed"
																: "bg-blue-500 hover:bg-blue-600 transition-colors"
														}`}
														>
															<FaUpload className="text-gray-300" />
															<span
																className={
																	statusUpload !== "success"
																		? "text-gray-200"
																		: "text-gray-200 font-semibold"
																}
															>
																Import
															</span>
														</button>
													</div>
													<div
														className={`flex relative flex-col items-center justify-center w-full border-[1px] border-dashed rounded-lg mb-4 h-24 bg-gray-800 border-gray-600`}
													>
														{isUploading && (
															<p className="text-md text-gray-400">
																Uploading... ({uploadProgress}%)
															</p>
														)}
														{!isUploading && statusUpload === "error" && (
															<p className="text-md text-gray-400">Error while uploading.</p>
														)}
														{!isUploading && statusUpload === "rate_limit" && (
															<p className="text-md text-gray-400">
																You have reached your upload limit.
															</p>
														)}
														{!isUploading && statusUpload === "success" && (
															<div className="flex flex-col w-full items-center justify-center gap-2 px-1">
																<p className="text-xs text-center lg:text-md text-gray-400">
																	{fileName}
																</p>
																<button
																	onClick={handleRemoveFile}
																	className="text-sm text-gray-200"
																>
																	<FaTrash className="text-xs text-gray-400" />
																</button>
															</div>
														)}
													</div>
												</>
											) : (
												<>
													{isSubtitleGenerated && (
														<div className="relative">
															<div className="flex flex-col gap-2">
																<div className="flex flex-col items-center justify-center w-full h-full">
																	<div className="flex items-center justify-center w-full h-full">
																		<div className="flex flex-col items-center justify-center">
																			<svg
																				className="w-10 h-10 text-gray-200 animate-spin"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="none"
																				viewBox="0 0 24 24"
																			>
																				<circle
																					className="opacity-25"
																					cx="12"
																					cy="12"
																					r="10"
																					stroke="currentColor"
																					strokeWidth="4"
																				/>
																				<path
																					className="opacity-75"
																					fill="currentColor"
																					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
																				/>
																			</svg>
																			<div className="flex gap-1 mt-3">
																				<span className="text-md font-medium text-gray-200">
																					Processing your short...
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="w-full text-center text-gray-400 italic font-semibold text-xs mt-2">
																	<span>Estimated time: 1 minute</span>
																</div>
															</div>
														</div>
													)}
													{!isSubtitleGenerated && statusSubtitle === "error" && (
														<div className="flex flex-col gap-6">
															<div className="flex flex-col gap-2">
																<p className="text-gray-200 text-lg font-bold text-center">
																	Oops, a problem occured... 😢
																</p>
																<p className="text-gray-400 text-sm font-italic text-center">
																	Verify that you have selected the right language, and if we can
																	clearly hear those who are speaking. Contact support if the
																	problem persists.
																</p>
															</div>
															<div className="flex gap-4 mx-auto">
																<button
																	whileHover={{ scale: 1.1 }}
																	whileTap={{ scale: 0.9 }}
																	className="flex items-center gap-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
																	onClick={() => {
																		setIsSubtitleGenerated(true);
																		setStatusSubtitle("");
																		handleSubtitleGeneration();
																	}}
																>
																	<BsArrowRepeat />
																	Retry
																</button>
																<button
																	whileHover={{ scale: 1.1 }}
																	whileTap={{ scale: 0.9 }}
																	className="flex items-center gap-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
																	onClick={() => {
																		window.$crisp.push(["do", "chat:open"]);
																	}}
																>
																	🤝 Help
																</button>
															</div>
														</div>
													)}
												</>
											)}
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
